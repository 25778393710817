<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-tabs centered icons-and-text next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows :value="activeTab">
				<v-tabs-slider color="primary"></v-tabs-slider>
				<v-tab v-for="section in menu" :key="section.key" @click="selectSection(section.route)">
					{{ section.title }}
					<v-icon>{{ section.icon }}</v-icon>
				</v-tab>
			</v-tabs>
		</v-flex>
		<v-flex scroll-y>
			<router-view />
		</v-flex>
	</v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'ApplicationsList',
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			menu: [
				{ icon: 'people', key: 1, route: 'sa-applications-list', title: this.$t('sa.applications.tabs_title.application_manager') },
				{
					icon: 'group_work',
					key: 2,
					route: 'sa-applications-subscriptions',
					title: this.$t('sa.applications.tabs_title.matrix_application_accounting_firm')
				}
			]
		}
	},
	computed: {
		activeTab: function () {
			const routeName = this.$route.name
			let result = this.menu.findIndex(section => section.route == routeName)
			if (result == -1) {
				result = null
			}
			return result
		}
	},
	methods: {
		selectSection: function (route) {
			this.appService.goTo(route)
		}
	}
}
</script>
